<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Assinatura</v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1" flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-list v-if="dados.plan" subheader one-line dense>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-file-document-edit-outline</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Plano</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ dados.plan.name }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-currency-usd</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Valor</v-list-item-title>
                                            <v-list-item-subtitle>R$ {{ (dados.value / 100).toFixed(2) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-cash-multiple</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Forma de Pagamento</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ dados.payment_method == 'banking_billet' ? 'Boleto/Pix' : 'Cartão' }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon color="#1e1e1e" class="grey lighten-1" dark>mdi-calendar-multiple-check</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Proximo vencimento</v-list-item-title>
                                            <v-list-item-subtitle>{{ datetime(dados.next_expire_at, 'DD/MM/YYYY') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-skeleton-loader
                                    v-else
                                    type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
                                />
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-card elevation="2" class="ma-1">
                                    <v-card-title class="text-center">Histórico de cobranças</v-card-title>
                                    <v-card-text>
                                        <v-data-table
                                            fixed-header
                                            :headers="headers"
                                            :items="historico"
                                            sort-by="emissao"
                                            :mobileBreakpoint="0"
                                            :loading="loadingGrid"
                                            :height="$vuetify.breakpoint.height - 280"
                                            :disable-pagination="true"
                                            :hide-default-footer="true"
                                        >
                                            <template v-slot:item.created_at="{ item }">
                                                {{ datetime(item.created_at, 'DD/MM/YYYY') }}
                                            </template>
                                            <template v-slot:item.status="{ item }">
                                                {{ translate(item.status) }}
                                            </template>
                                            <template v-if="dados.payment_method == 'banking_billet'" v-slot:item.action="{ item }">
                                                <v-btn
                                                    @click="downloadBolix(item.charge_id)"
                                                    color="success"
                                                    depressed
                                                    :loading="loadingBtn"
                                                    :disabled="['settled', 'paid'].includes(item.status)"
                                                >Boleto/Pix</v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'

export default {
    name: 'MinhaAssinatura',

    components: { BaseContainer },

    data: () => ({
        loadingGrid: false,
        loadingBtn: false,
        dados: {},
        headers: [
            { text: 'Emissão', value: 'created_at' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'action' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    computed: {
        historico() {
            return this.dados.history || [];
        },
    },

    methods: {
        consultar() {
            this.loadingGrid = true;
            this.dados = {};

            this.$http.post('assinaturas/detalhes').then(resp => {
                this.dados = resp.data.data;
            })
            .catch(() => {
                alert('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loadingGrid = false));
        },

        downloadBolix(cobranca_id) {
            this.loadingBtn = true;
            this.$http.post('assinaturas/cobranca-detalhes', { cobranca_id }).then(resp => {
                const url = resp.data?.data?.payment?.banking_billet?.link;

                url && window.open(url, '_blank');
            })
            .catch(() => {
                alert('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loadingBtn = false));
        },

        datetime(dh, format = 'DD/MM/YYYY HH:mm') {
            return dh ? this.moment(dh).format(format) : '';
        },

        translate(s) {
            if (s == 'settled') return 'Pago manual';
            if (s == 'paid') return 'Pago';
            if (s == 'unpaid') return 'Vencido';
            if (s == 'new') return 'Novo';
            if (s == 'waiting') return 'Aguardando';
            if (s == 'canceled') return 'Cancelado';
            if (s == 'active') return 'Ativo';
            return s;
        },
    },
}
</script>
